const msal = require('@azure/msal-browser');

function LogInAllMsalResources(localMsalResources)
{
    // The inital call sets the all logged-in call back function
    // Each resource recursively calls this function without a call back argument
    // to login the next resource. When all resources are logged-in the call back
    // is executed.

    console.log("Executing LogInAllMsalResources ...");
    console.log(`msalResources -->`)
    console.log(localMsalResources);    

    let nextMsalResource = localMsalResources.find(obj=> {return obj.isLoggedIn == false})
    //We need to wait for the login to finish before logging in the next resource
    //When this is finished logging in MyMSAL class will recursively call this until all
    //resources are logged in, at that point MyMSAL will call the all logged in function
    //provided to it.
    nextMsalResource.signIn(LogInAllMsalResources); 
}

let signOutIndex = null;

function LogOutAllMsalResources(localMsalResources)
{
    console.log("LogOutAllMsalResources");        

    if(signOutIndex == null){
        signOutIndex = localMsalResources.length;        
    }
                
    signOutIndex = signOutIndex -1;
    console.log(`singOutIndex : ${signOutIndex}`);
    if(signOutIndex == -1)
    {   
        return;
    }

    if(signOutIndex > 0) //last resource use the configured uriredirect
    {
        console.log("signOutNoRedirect");
        localMsalResources[signOutIndex].signOutNoRedirect();
    }
    else
    {
        console.log("signOut")
        localMsalResources[signOutIndex].signOut();
    }
}


//Supply the function to logout all resources
//This function needs a reference to the msal resources array
//and call LogOutAllMsalResources(<the msal resources array>)
function createMsalLogger(logOutAllfn)
{

  //This is the msal logging function. In addition to debug monitoring
  //As a work around this is monitoring the logs to determine if a resource
  //has been successfully logged-out and log out all the other resources. 
  return function fnMsalLogger(level, message, containsPii)
  {
    if (containsPii) {
      return;
    }
    switch (level) {
      case msal.LogLevel.Error:
        console.error(message);
        return;
      case msal.LogLevel.Info:
        console.info(message);
        //When logging out log-out all other resources.
        //This is bit of a hack and ugly code still locking for a better solution.
        if(message.includes("msal:logoutEnd"))
        {
          console.log("calling LogOutAllMsalResources from loggerCallback");
          logOutAllfn();
        }
        return;
      case msal.LogLevel.Verbose:
        console.debug(message);
        return;
      case msal.LogLevel.Warning:
        console.warn(message);
        return;    
    }
  }

}


module.exports = {
  LogInAllMsalResources,
  LogOutAllMsalResources,
  createMsalLogger,
  signOutIndex
};