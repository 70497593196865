
const {generateB2CConfig} = require("./generateB2CConfig");
const { LogInAllMsalResources, LogOutAllMsalResources, createMsalLogger} = require("./msalResources.js");
// eslint-disable-next-line
let {signOutIndex} = require("./msalResources.js");
const MyMSAL = require("./myMSAL.js");
const {apiGetGeneral,callApi,callToken} = require("./msalApi.js");
//const {coreMSALConfig} = require("./coreMSALConfig");
const {mobileMSALConfig} = require("./cfgMSALMobile");
const { default: store } = require("../state/store");
console.log('loading msalInterface.js');

//Module level variables
let msalResources = [];
let coreMSALObj = null;
let mobileMSALObj = null;
let allLoggedInFunction = null;


async function InitMsal3()
{
    console.log("Executing InitMsal3...")
    signOutIndex = null;
    msalResources = [];
    let cfg = mobileMSALConfig;
    console.log("mobileMSALConfig => ");
    console.log(cfg);
    mobileMSALObj = new MyMSAL(cfg,msalResources, null);
    mobileMSALObj.apiUri = process.env.VUE_APP_WLT_API; //"https://localhost:7165/";
    msalResources.push(mobileMSALObj);
    console.log('mobileMSALObj -->');
    console.log(mobileMSALObj);
    console.log("InitMsal3 completed.");
}

async function SignIn3(loginComplete)
{
    console.log('Executing SignIn3 ...');
    mobileMSALObj.signIn(loginComplete);
    console.log('SignIn3 completed.');
}


function SignOutResources() 
{
    console.log("SignOutResources in app.js");
    console.log("msalResources --> ");
    console.log(msalResources);
    LogOutAllMsalResources(msalResources);   
}

let msalLoggerFn = createMsalLogger(SignOutResources);
console.log("msalLoggerFn --> ");
console.log(msalLoggerFn);


async function InitMsal(b2cEnvironmentSettings,allLoggedInFn)
{

    let b2cEnv = b2cEnvironmentSettings; //Import

    console.log('InitMsal executing...');
    console.log('b2cEnv ==>');
    console.log(b2cEnv);
    console.log(b2cEnvironmentSettings);
    //Setting up mobile access
    const mobileMSALConfig = generateB2CConfig(b2cEnv, 
        b2cEnv.resources.find(resource => resource.name === "mobileMSAL"));
    mobileMSALObj = new MyMSAL(mobileMSALConfig,msalResources,msalLoggerFn);
    console.log("mobileMSALObj.system.loggerOptions.loggerCallback -->");
    console.log(mobileMSALObj.myMSALConfig.system.loggerOptions.loggerCallback);
    msalResources.push(mobileMSALObj);

    //Setting up core access
    const coreMSALConfig = generateB2CConfig(b2cEnv,
        b2cEnv.resources.find(resource => resource.name === "coreMSALConfig"));
    coreMSALObj = new MyMSAL(coreMSALConfig,msalResources, msalLoggerFn);
    msalResources.push(coreMSALObj);

    msalResources[msalResources.length - 1].allLoggedInCallBack = AllResourcesLoggedIn;
    allLoggedInFunction = allLoggedInFn;

}




//If the user hits page refresh
//This doesnt work quite right
function SelectAccount()
{
    console.log('msalInterface.SelectAccount executing ....')
    mobileMSALObj.selectAccount();
}


//Callback function after all resources have been logged in.
function AllResourcesLoggedIn()
{    
    console.log(`msalResources.count: ${msalResources.length}`);
    //welcomeUser(msalResources[0].username);
    allLoggedInFunction();
}   

function signIn2()
{
    console.log("signIn2 executing");
    // msalResources[0].signIn(msalSignInSuccessResponse); //success will call msalSignInSuccessResponse and sign in the other resources
    LogInAllMsalResources(msalResources);
}

function signOut2()
{
    console.log("msalResources -->")
    console.log(msalResources);

    LogOutAllMsalResources(msalResources);
}

function editProfile()
{
    msalResources[0].editProfile();
}

async function apiCoreGet(method,parameters)
{
    console.log(`Executing apiCoreGet calling endpoint ${method}/${parameters}`);
    let result = await apiGetGeneral(method, parameters, mobileMSALObj);
    console.log("apiCoreGet result==>")
    console.log(result);
    return result;
}

function apiMobileGet(method, parameters)
{

    apiGetGeneral(method, parameters, mobileMSALObj);
}

function GetUserName()
{
    return mobileMSALObj.username;
}

function callApiPublicMethod() {

    console.log("callApiPublicMethod() start...")
  
      try {
          callApi(coreMSALObj.apiUri + "Public", "");
      } catch(error) {
          console.log(error); 
      }    
}

async function acquireAndSetTokens() {
    await callToken(mobileMSALObj,store)
}

module.exports = {
    // generateB2CConfig: generateB2CConfig,
    LogInAllMsalResources: LogInAllMsalResources,
    LogOutAllMsalResources: LogOutAllMsalResources,
    // createMsalLogger: createMsalLogger,
    // MyMSAL: MyMSAL,
    SignOutResources: SignOutResources,
    InitMsal: InitMsal,
    SelectAccount: SelectAccount,
    AllResourcesLoggedIn: AllResourcesLoggedIn,
    signIn2: signIn2,
    signOut2: signOut2,
    editProfile: editProfile,
    apiCoreGet: apiCoreGet,
    apiMobileGet: apiMobileGet,
    GetUserName: GetUserName,
    callApiPublicMethod: callApiPublicMethod,
    InitMsal3 : InitMsal3,
    SignIn3 : SignIn3,acquireAndSetTokens
  };
  