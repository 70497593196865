const msal = require('@azure/msal-browser');


// mobileb2cScopes = ["https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.read","https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.write"];

//To use this configuration for another resource:
// Change msalConfigName to an appropriate name
// Rename the function to an appropriate name
// Rename the const variable at the bottom of the file to an approiate name
// Set the myb2cScopes to match your resource/api scopes
// Set the configuration according to the resource registration if different
// Requires redirectCfg found in redirect.js
const redirectCfg = {
  //redirectUri: "http://localhost:8080" // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  redirectUri: process.env.VUE_APP_URL//"https://wltadminwebdev.azurewebsites.net/"
}

function mobileMSALConfigFn() {

  //Adding another API config change the scopes variable here and in the configuration
  let myb2cScopes = ["https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.read","https://weightlossdirect.onmicrosoft.com/8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c/task.write"];

  let myb2cPolicies =  {
    names: {
        signUpSignIn: "B2C_1_susi",
        editProfile: "B2C_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://weightlossdirect.b2clogin.com/weightlossdirect.onmicrosoft.com/B2C_1_susi",
        },
        editProfile: {
            authority: "https://weightlossdirect.b2clogin.com/weightlossdirect.onmicrosoft.com/B2C_1_edit_profile"
        }
    },
    authorityDomain: "weightlossdirect.b2clogin.com"
  };

  //Change the scores variable name
  var mymsalConfig = {
      name:"mobileMSAL", //Must be unique used for signing out all logged in resources
      auth: {
        clientId: "8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c", //WLTB2CApp -- This is the ONLY mandatory field; everything else is optional.
        authority: myb2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [myb2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: redirectCfg.redirectUri, //redirect in it's own cfg so each resource does not need to be modified
      },    
      cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
      },
      b2cScopes : myb2cScopes,
      loginRequest : {scopes: ["openid", ...myb2cScopes]},
      tokenRequest : {
          scopes: [...myb2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
          forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
        },
        b2cPolicies : myb2cPolicies,
        authorityDomain: "weightlossdirect.b2clogin.com",
        system: {
          loggerOptions: {
              loggerCallback: fnMsalLogger
          },
      },         
    }

  console.log(`setting up ${mymsalConfig.msalConfigName} configuration`);
  return mymsalConfig;

  
}


//This is the msal logging function. In addition to debug monitoring
//As a work around this is monitoring the logs to determine if a resource
//has been successfully logged-out and log out all the other resources. 
function fnMsalLogger(level, message, containsPii){
  if (containsPii) {
    return;
  }
  switch (level) {
    case msal.LogLevel.Error:
      console.error(message);
      return;
    case msal.LogLevel.Info:
      console.info(message);
      //When logging out log-out all other resources.
      //This is bit of a hack and ugly code still locking for a better solution.
      // if(message.includes("msal:logoutEnd"))
      // {
      //   console.log("calling LogOutAllMsalResources from loggerCallback");
      //   LogOutAllMsalResources();
      // }
      return;
    case msal.LogLevel.Verbose:
      console.debug(message);
      return;
    case msal.LogLevel.Warning:
      console.warn(message);
      return;    
  }
}

//Change the variable name
const mobileMSALConfig = mobileMSALConfigFn();

module.exports = {mobileMSALConfig};