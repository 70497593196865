// b2cCfg: an object representing the configuration for a B2C policy.
// resourceCfg: an object representing the configuration for a MSAL resource.
// creates an object named mymsalConfig that represents the MSAL configuration for the given resource.
// It sets up the configuration for authentication with the Azure AD B2C, 
// including the client ID, authority URL, scopes, and policies.

// The function returns the mymsalConfig object.


function generateB2CConfig(b2cCfg, resourceCfg, msalLoggerFn) {

  console.log("b2cConfig -->");
  console.log(b2cCfg);
  console.log("resourceCfg -->");  
  console.log(resourceCfg);
  console.log(`entering config for ${resourceCfg.name} configuration`);
  
  //Adding another API config change the scopes variable here and in the configuration
  let myb2cScopes =  resourceCfg.scopes;

  let myb2cPolicies =  {
    names: {
        signUpSignIn: b2cCfg.signUpSignIn,
        editProfile: b2cCfg.editProfile
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${b2cCfg.authority}/${b2cCfg.domain}/${b2cCfg.signUpSignIn}`,
        },
        editProfile: {
            authority: `https://${b2cCfg.authority}/${b2cCfg.domain}/${b2cCfg.editProfile}`
        }
    },
    authorityDomain: b2cCfg.authority
  };

  //Change the scores variable name
  let mymsalConfig = {
      name:resourceCfg.name, //Must be unique used for signing out all logged in resources
      apiUri: resourceCfg.apiUri,
      auth: {
        clientId: resourceCfg.applicationID, // "8e00d800-c2f6-4cfd-ac0d-6a37d9fbaf7c", //WLTB2CApp -- This is the ONLY mandatory field; everything else is optional.
        authority: myb2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
        knownAuthorities: [myb2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        redirectUri: b2cCfg.redirectUri, //redirect in it's own cfg so each resource does not need to be modified
      },    
      cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
      },
      b2cScopes : myb2cScopes,
      loginRequest : {scopes: ["openid", ...myb2cScopes]},
      tokenRequest : {
          scopes: [...myb2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
          forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
        },
        b2cPolicies : myb2cPolicies,
        authorityDomain: myb2cPolicies.authorityDomain,
        system: {
          loggerOptions: {
              loggerCallback: msalLoggerFn
          },
      },         
    }

  console.log(`setting up ${mymsalConfig.name} configuration v2.0`);
  return mymsalConfig;
}

module.exports = {generateB2CConfig};

