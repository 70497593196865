const msal = require('@azure/msal-browser');
class MyMSAL {
    constructor(myMSALConfig, msalResources,msalLoggerFnLocal) {

        if(msalLoggerFnLocal !== undefined && msalLoggerFnLocal !== null)
            myMSALConfig.system.loggerOptions.loggerCallback = msalLoggerFnLocal;

        this.myMSALConfig = myMSALConfig;
        this.name = myMSALConfig.name;
        this.apiUri = myMSALConfig.apiUri;
        this.msalObj = new msal.PublicClientApplication(myMSALConfig);
        this.accountId = null;
        this.username = null;
        this.userAccount = null;
        this.successResponseCallBack = null;
        this.isLoggedIn = false;
        this.signInSuccessResponse = null;
        this.msalResources = msalResources; //need the msal resources array to call the next resource
        this.allLoggedInCallBack = null; //set the call back for the last resource
        console.log(`MyMSAL constructor for ${this.name}, this.msalResources = msalResources; this.msalResources -->`)
        console.log(this.msalResources);
        console.log("this.myMSALConfig -->");
        console.log(this.myMSALConfig);
    }

    signIn(successResponse) {
        console.log(`MyMSAL signIn for ${this.name}, this.msalResources -->`)
        console.log(this.msalResources);

        this.successResponseCallBack = successResponse;
        /**
         * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
         */
        console.log(`msal signing in with popup for resource ${this.myMSALConfig.name}`)
        this.msalObj.loginPopup(this.myMSALConfig).then(this.handleSignInResponse.bind(this))
        .catch(error => {
            console.log(error);
            throw error;
        });        
    }

    handleSignInResponse(response) {    
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */

        console.log("handleSignInResponse(reponse) executing, response -->");        
        console.log(response);
        console.log(`MyMSAL signIn for ${this.name}, this.msalResources -->`)
        console.log(this.msalResources);    

        if (response !== null) {
            
            console.log(`signed in ${this.myMSALConfig.name}`)
            this.signInSuccessResponse = response;
            this.setAccount(response.account);
            this.isLoggedIn = true;
            // console.log('This is handle scuess response inside of of handleSignInResponse:')
            // console.log(this.successResponseCallBack);
        if(this.allLoggedInCallBack !== undefined && this.allLoggedInCallBack != null)
        {
            this.allLoggedInCallBack();
        }
        else if(this.successResponseCallBack !== undefined && this.successResponseCallBack != null)
                this.successResponseCallBack(this.msalResources);
            //msalSignInSuccessResponse(response);
    
        } else {
            console.log("response null running selectAccount()");
            this.selectAccount();
        }
    }

    //#TODO
    checkIfUserIsLoggedIn()
    {
        //selectAccount in the original solution would do the things
        //that would happen after a sucessful login
        //this probably needs to be changed to take a call back function
        //instead of referencing global variables
    }

    //#TODO Should probably change this up to return false if there is no current account
    //If there is a cached account (the user is already logged in) selectAccount sets the account
    //¿¿ If they are signed in with multiple resources how do we select the correct resource with the correct scopes ??
    selectAccount() {

        console.log('Selecting account...');
        /**
         * See here for more info on account retrieval: 
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
    
        const currentAccounts = this.msalObj.getAllAccounts();
    
        if (currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
    
            /**
             * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
             * is cached as a new account, which results in more than one account in the cache. Here we make
             * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow, 
             * as this is the default flow the user initially signed-in with.
             */
            const accounts = currentAccounts.filter(account =>
                account.homeAccountId.toUpperCase().includes(this.myMSALConfig.b2cPolicies.names.signUpSignIn.toUpperCase())
                &&
                account.idTokenClaims.iss.toUpperCase().includes(this.myMSALConfig.b2cPolicies.authorityDomain.toUpperCase())
                &&
                account.idTokenClaims.aud === this.myMSALConfig.auth.clientId 
                );
    
            if (accounts.length > 1) {
                // localAccountId identifies the entity for which the token asserts information.
                if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
                    // All accounts belong to the same user
                    this.setAccount(accounts[0]);
                } else {
                    // Multiple users detected. Logout all to be safe.
                    this.signOut();
                }
            } else if (accounts.length === 1) {
                this.setAccount(accounts[0]);
            }
    
        } else if (currentAccounts.length === 1) {
            this.setAccount(currentAccounts[0]);
        }
    }
    
    //This is called in at least two manners
    //The user goes through the login process (popup)
    //the user is already logged in
    setAccount(account)
    {
        console.log(`setting account: ${account.username}`);
        console.log(account);
        this.userAccount = account;
        this.accountId = account.homeAccountId;
        this.username = account.username;
        console.log("setting account complete");
    }

    getTokenPopup() {

        console.log("getTokenPopup() executing....")
        let request = this.myMSALConfig.tokenRequest;
        /**
        * See here for more information on account retrieval: 
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        */
        request.account = this.msalObj.getAccountByHomeId(this.accountId);

        console.log("account");
        console.log(request.account);
            
        /**
         * 
         */
        return this.msalObj.acquireTokenSilent(request)
            .then((response) => {
                // In case the response from B2C server has an empty accessToken field
                // throw an error to initiate token acquisition
                if (!response.accessToken || response.accessToken === "") {
                    throw new msal.InteractionRequiredAuthError;
                }
                return response;
            })
            .catch(error => {
                console.log("Silent token acquisition fails. Acquiring token using popup. \n", error);
                if (error instanceof msal.InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return this.msalObj.acquireTokenPopup(request)
                        .then(response => {
                            console.log(response);
                            return response;
                        }).catch(error => {
                            console.log(error);
                        });
                } else {
                    console.log(error);
                }
            });
    }

    signOut()
    {
        console.log(`logging out ${this.myMSALConfig.msalConfigName}`);

        let logoutRequest = {
                postLogoutRedirectUri: this.myMSALConfig.auth.redirectUri,
                mainWindowRedirectUri: this.myMSALConfig.auth.redirectUri
            };
        
        this.isLoggedIn = false;
        this.msalObj.logoutPopup(logoutRequest);

    }

    signOutNoRedirect()
    {
        console.log(`logging out ${this.myMSALConfig.msalConfigName}`);

        let logoutRequest = {
            postLogoutRedirectUri: ''};
    
            this.msalObj.logoutPopup(logoutRequest);         
    }

    /**
     * To initiate a B2C user-flow, simply make a login request using
     * the full authority string of that user-flow e.g.
     * https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2 
     */
    editProfile() {
    
        const editProfileRequest =  this.myMSALConfig.b2cPolicies.authorities.editProfile;  //b2cPolicies.authorities.editProfile;
        editProfileRequest.loginHint = this.msalObj.getAccountByHomeId(this.accountId).username;

        this.msalObj.loginPopup(editProfileRequest)
            .catch(error => {
                console.log(error);
            });
    }

} //End Class

module.exports = MyMSAL;
